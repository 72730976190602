import React from 'react';
import GatsbyImage from 'gatsby-image';

import { useGraphQL } from '../hooks';
import { siteContact } from '../data';

function ContactUs() {
  const { contactSectionHero } = useGraphQL();

  return (
    <article className="relative bg-white">
      <div className="w-full px-4 py-8 mx-auto md:py-16 max-w-7xl sm:px-6 lg:px-8">
        <div className="grid justify-center gap-8 sm:gap-14 md:grid-cols-2">
          <div className="w-full mx-auto max-w-prose">
            <div className="relative h-0 aspect-ratio-square">
              <div className="absolute inset-0 flex bg-sky-blue">
                <GatsbyImage
                  fluid={contactSectionHero.childImageSharp.fluid}
                  className="flex-1"
                />
              </div>
            </div>
          </div>
          <div className="flex items-center md:py-12">
            <div>
              <h2 className="text-2xl font-bold tracking-wider text-center uppercase text-light-blue md:text-left">
                Contact Us
              </h2>

              <dl className="mt-6 space-y-4">
                <div className="sm:flex">
                  <dt className="text-lg font-bold tracking-wide w-28 text-light-blue">
                    Phone:
                  </dt>
                  <div>
                    {siteContact.phones.map((phone) => (
                      <dd key={phone.id} className="text-base">
                        {phone.name}:
                        <a
                          href={`tel:${phone.number.split(' ').join('')}`}
                          className="hover:underline focus:underline"
                        >
                          {' '}
                          {phone.number}
                        </a>
                      </dd>
                    ))}
                  </div>
                </div>
                <div className="sm:flex">
                  <dt className="text-lg font-bold tracking-wide w-28 text-light-blue">
                    Address:
                  </dt>
                  <div>
                    {siteContact.postalAddresses.map((address) => (
                      <dd key={address.id} className="text-base">
                        <a
                          href="/"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="hover:underline focus:underline"
                        >
                          {address.line1}, {address.line2}
                        </a>
                      </dd>
                    ))}
                  </div>
                </div>
                <div className="sm:flex">
                  <dt className="text-lg font-bold tracking-wide w-28 text-light-blue">
                    Email:
                  </dt>
                  <div>
                    {siteContact.emails.map((emailAddress) => (
                      <dd key={emailAddress.id} className="text-base">
                        {emailAddress.name}:
                        <a
                          href={`mailto:${emailAddress.email}`}
                          className="hover:underline focus:underline"
                        >
                          {' '}
                          {emailAddress.email}
                        </a>
                      </dd>
                    ))}
                  </div>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
}

export { ContactUs };
