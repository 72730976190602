import { nanoid } from 'nanoid';

import { FacebookIcon, InstagramIcon } from '../components/vectors';

const mainNavigation = [
  {
    id: nanoid(),
    label: 'About',
    slug: '/about/',
  },
  {
    id: nanoid(),
    label: 'Services',
    slug: '/services/',
  },
  // {
  //   id: nanoid(),
  //   label: 'Recent Projects',
  //   slug: '/recent-projects/',
  // },
  // {
  //   id: nanoid(),
  //   label: 'Our Terms of trade',
  //   slug: '/terms-of-trade /',
  // },
  {
    id: nanoid(),
    label: 'Contact',
    slug: '/contact/',
  },
];

const socialLinks = [
  {
    id: nanoid(),
    label: 'Instagram',
    url: 'https://www.instagram.com',
    icon: InstagramIcon,
  },
  {
    id: nanoid(),
    label: 'Facebook',
    url: 'https://www.facebook.com',
    icon: FacebookIcon,
  },
];

export { mainNavigation, socialLinks };
