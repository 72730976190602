import React from 'react';
import { Link } from 'gatsby';
import Image from 'gatsby-image';

import { useGraphQL } from '../hooks';
import { mainNavigation, siteContact } from '../data';

function Footer() {
  const {
    site: {
      siteMetadata: { title },
    },
    logoImage,
  } = useGraphQL();
  return (
    <footer>
      <div className="w-full px-4 py-10 mx-auto sm:px-6 max-w-7xl lg:px-8">
        <div className="grid grid-cols-3 gap-8 lg:gap-0">
          <div className="col-span-3 lg:col-span-1">
            <div className="flex justify-center">
              <Link to="/">
                <span className="sr-only">{title}</span>
                <Image
                  aria-hidden
                  focusable={false}
                  fluid={logoImage.childImageSharp.fluid}
                  className="h-auto w-36 md:w-44"
                />
              </Link>
            </div>
          </div>
          <div className="grid col-span-3 gap-6 pb-4 border-b border-white lg:grid-cols-3 lg:col-span-2">
            <div>
              <h2 className="text-lg font-bold tracking-wide w-28 text-light-blue">
                Links
              </h2>
              <ul className="flex flex-col justify-between space-y-1">
                {mainNavigation.map((node) => (
                  <li key={node.id}>
                    <Link
                      to={node.slug}
                      className="tracking-wide hover:underline focus:underline"
                    >
                      {node.label}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="lg:col-span-2">
              <dl className="space-y-4">
                <div className="sm:flex">
                  <dt className="text-lg font-bold tracking-wide w-28 text-light-blue">
                    Phone:
                  </dt>
                  <div>
                    {siteContact.phones.map((phone) => (
                      <dd key={phone.id} className="text-base">
                        {phone.name}:{' '}
                        <a
                          href={`tel:${phone.number.split(' ').join('')}`}
                          className="hover:underline focus:underline"
                        >
                          {phone.number}
                        </a>
                      </dd>
                    ))}
                  </div>
                </div>
                <div className="sm:flex">
                  <dt className="text-lg font-bold tracking-wide w-28 text-light-blue">
                    Address:
                  </dt>
                  <div>
                    {siteContact.postalAddresses.map((address) => (
                      <dd key={address.id} className="text-base">
                        <a
                          href="/"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="hover:underline focus:underline"
                        >
                          {address.line1}, {address.line2}
                        </a>
                      </dd>
                    ))}
                  </div>
                </div>
                <div className="sm:flex">
                  <dt className="text-lg font-bold tracking-wide w-28 text-light-blue">
                    Email:
                  </dt>
                  <div>
                    {siteContact.emails.map((emailAddress) => (
                      <dd key={emailAddress.id} className="text-base">
                        {/* {emailAddress.name}:{' '} */}
                        <a
                          href={`mailto:${emailAddress.email}`}
                          className="hover:underline focus:underline"
                        >
                          {emailAddress.email}
                        </a>
                      </dd>
                    ))}
                  </div>
                </div>
              </dl>
            </div>
          </div>
          <div className="col-span-3 py-2 border-b lg:col-span-2 lg:col-start-2">
            <p className="text-sm font-light tracking-wider text-center lg:text-left">
              <a
                href="https://pd.design"
                target="_blank"
                rel="noopener noreferrer"
                className=" hover:underline focus:underline"
              >
                Designed and developed by PD
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export { Footer };
