import React from 'react';

import { ContactForm } from './contact-form';

function LeaveAMessage() {
  return (
    <article className="relative bg-gray-100">
      <div className="w-full px-4 py-8 mx-auto md:py-16 max-w-7xl sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold tracking-wider text-center uppercase text-light-blue">
          Leave us a message
        </h2>
        <p className="text-xl tracking-wide text-center">
          and we'll get back to you shortly
        </p>

        <div className="flex justify-center mt-8">
          <div className="w-full max-w-3xl">
            <ContactForm />
          </div>
        </div>
      </div>
    </article>
  );
}

export { LeaveAMessage };
