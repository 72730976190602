import React from 'react';

import { siteContact } from '../data';

function TopBar() {
  return (
    <div className="bg-grey">
      <div className="flex items-center justify-center w-full px-4 py-2 mx-auto space-x-6 text-white md:justify-end sm:px-6 lg:px-8 max-w-7xl">
        <p className="font-bold tracking-wider text-center md:text-left">
          {siteContact.phones[0].name}:{' '}
          <a href={`tel:${siteContact.phones[0].number.split(' ').join(' ')}`}>
            {siteContact.phones[0].number}
          </a>{' '}
          <br className="md:hidden" />
          <span className="hidden mr-0 md:inline-block md:mr-6" />
          {siteContact.phones[1].name}:{' '}
          <a href={`tel:${siteContact.phones[1].number.split(' ').join(' ')}`}>
            {siteContact.phones[1].number}
          </a>
        </p>
      </div>
    </div>
  );
}

export { TopBar };
