import React from 'react';
import PropTypes from 'prop-types';

import { ContactForm } from './contact-form';
import { siteContact } from '../data';

function ContactSection({ bgColorClass }) {
  return (
    <article className={`relative ${bgColorClass || 'bg-navy'}`}>
      <div className="grid w-full gap-6 px-4 py-12 mx-auto sm:px-6 lg:px-8 max-w-7xl lg:grid-cols-4">
        <ContactInfo />
        <ContactForm />
      </div>
    </article>
  );
}

function ContactInfo() {
  return (
    <div className="text-grey md:col-span-2">
      <h2 className="text-2xl font-bold tracking-wider text-center uppercase md:text-left text-light-blue">
        Contact Us
      </h2>

      <dl className="mt-6 space-y-4">
        <div className="sm:flex">
          <dt className="text-lg font-bold tracking-wide w-28 text-light-blue">
            Phone:
          </dt>
          <div>
            {siteContact.phones.map((phone) => (
              <dd key={phone.id} className="text-base">
                {phone.name}:
                <a
                  href={`tel:${phone.number.split(' ').join('')}`}
                  className="hover:underline focus:underline"
                >
                  {' '}
                  {phone.number}
                </a>
              </dd>
            ))}
          </div>
        </div>
        <div className="sm:flex">
          <dt className="text-lg font-bold tracking-wide w-28 text-light-blue">
            Address:
          </dt>
          <div>
            {siteContact.postalAddresses.map((address) => (
              <dd key={address.id} className="text-base">
                <a
                  href="/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:underline focus:underline"
                >
                  {address.line1}, {address.line2}
                </a>
              </dd>
            ))}
          </div>
        </div>
        <div className="sm:flex">
          <dt className="text-lg font-bold tracking-wide w-28 text-light-blue">
            Email:
          </dt>
          <div>
            {siteContact.emails.map((emailAddress) => (
              <dd key={emailAddress.id} className="text-base">
                {/* {emailAddress.name}: */}
                <a
                  href={`mailto:${emailAddress.email}`}
                  className="hover:underline focus:underline"
                >
                  {' '}
                  {emailAddress.email}
                </a>
              </dd>
            ))}
          </div>
        </div>
      </dl>
    </div>
  );
}

ContactSection.propTypes = {
  bgColorClass: PropTypes.string,
};
export { ContactSection };
