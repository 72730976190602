const defaultTheme = require('tailwindcss/defaultTheme');
const aspectRatio = require('tailwindcss-aspect-ratio');
const tailwindUI = require('@tailwindcss/ui');

// See https://tailwindcss.com/docs/configuration for details
module.exports = {
  purge: ['./src/**/*.{js,jsx,ts,tsx}'],
  darkMode: false, // or 'media' or 'class'
  theme: {
    aspectRatio: {
      none: 0,
      square: [1, 1],
      '16/9': [16, 9],
      '4/3': [4, 3],
      '21/9': [21, 9],
      '3/4': [3, 4],
    },
    typography: (theme) => ({
      default: {
        css: {
          'ol > li::before': {
            color: theme("colors.['light-blue']"),
          },
          'ul > li::before': {
            backgroundColor: theme("colors.['light-blue']"),
          },
        },
      },
    }),
    extend: {
      colors: {
        'light-blue': '#00c0f2',
        grey: '#1d1d1d',
      },
      fontFamily: {
        sans: ['Poppins', ...defaultTheme.fontFamily.sans],
      },
    },
  },
  // Default values here: https://tailwindcss.com/docs/pseudo-class-variants#default-variants-reference
  variants: {
    extend: {
      borderColor: ['group-focus'],
      boxShadow: ['group-focus'],
    },
  },
  plugins: [
    // See https://github.com/webdna/tailwindcss-aspect-ratio for details
    aspectRatio,
    // See https://tailwindui.com/documentation for details
    tailwindUI,
  ],
};
