import { nanoid } from 'nanoid';

const siteContact = {
  phones: [
    { id: nanoid(), name: 'Steve', number: '0458 005 858' },
    { id: nanoid(), name: 'Taylah', number: '0400 142 555' },
  ],
  emails: [
    { id: nanoid(), name: 'North West', email: 'steve@midstatepower.com.au' },
    { id: nanoid(), name: 'Admin', email: 'taylah@midstatepower.com.au' },
  ],
  postalAddresses: [
    { id: nanoid(), line1: 'PO Box 364, Tamworth', line2: 'NSW 2340' },
  ],
};

export { siteContact };
